<template>
	<div>
		<div class="des">Here,You Can Share Your Thoughts With Us</div>
		<el-row class='shareContainer' :gutter="20"  v-loading="loading">
			<el-col :span='8' :xs="24" class='shareList' v-for="(item,index) in shareData" :key='index'>
				<el-row style='padding-top: 10px;'>
					<el-col :span='6'>
						<el-avatar :src='item.avatar'></el-avatar>
					</el-col>
					<el-col :span='18'>
						<el-row>
							<el-col :span='24' class='nickName'>{{item.name}}</el-col>
						</el-row>
						<el-row>
							<el-col :span='24' class='remarkDate'>{{item.remar_date}}</el-col>
						</el-row>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span='24' class='des'>
						{{item.des}}
					</el-col>
				</el-row>
				<el-row style='overflow: hidden;'>
					<el-col :span='24'>
						<el-image class='mainImg' :src='item.img' style='width: 100%;height: 165px;'>
						</el-image>
					</el-col>
				</el-row>
				<el-row style='margin-bottom: 10px;'>
					<el-col :span='4'>
						<i class='iconfont' style="cursor: pointer;" isThum='false' @click="thumbsUp($event,item.id)">&#xec7f;</i>
						<span>{{item.thumbs}}</span>
					</el-col>
					<el-col :span='4'>
						<span class="el-icon-chat-dot-square"></span>
						<span>{{item.remarks}}</span>
					</el-col>
					<el-col :span='2' :offset='14'>
						<span class="el-icon-full-screen" style='margin-left: 9px;cursor: pointer;'></span>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span='24' class='showMore'>
				<span @click='showMore'>查看更多.....</span>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span='24'>
				<el-divider></el-divider>
			</el-col>
		</el-row>
		<p>你好,陌生人.每个人都有欢乐和孤独,有些话,我觉得可以在这里说出来,当成一个发泄的方式!</p>
		<el-row style='margin-top: 30px;'>
			<el-col :span='24'>
				<el-upload 
					class="upload-demo" 
					drag
					name='image'
					:multiple='false'
					:limit='1'
					ref="upload"
					:on-exceed='isToolong'	
					action
					:on-remove="handleRemove" 
					:file-list="fileList"
					list-type="picture"
					:auto-upload="false"
					:on-success='isUploadSuccess'
					:on-error='isUploadFail'
					:http-request='handleUploadForm'
					:on-progress='isOnUpload'
					:on-change='isChangeUpload'
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</el-col>
			<el-col :span='24' style='margin-top: 5px;'>
				<el-input clearable placeholder='请分享点有意思或者烦心事吧^_^' size='mini' maxlength='50' v-model='text'
					show-word-limit></el-input>
			</el-col>
			<el-col :span='24' style='margin-top: 10px;margin-bottom: 20px;'>
				<el-button type='primary' size='mini' @click="submitUpload">Submit Now!</el-button>
				<span style="color: rgb(134, 134, 134); margin-left: 10px;">(Tip:博主的服务器很差,传图片可能比较慢,请谅解！)</span>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { clientShareImg,clientShareInit,clientShareShowMore,clientShareThumbs } from '../../server/api/index.js';
	import { getUSERid } from '../../server/api/store.js';
	import { current } from '../../server/api/tool.js';
	export default {
		data() {
			return {
				text: '',
				fileList: [],
				isUploadimg:false,
				shareData:[],
				loading: true
			}
		},
		methods: {
			submitUpload() {
				if(!QC.Login.check()) return this.$myMessage({msg:'请登录!',icon:'el-icon-error',color:'color:red;',duration:2000});
				if( this.text.length === 0 && !this.isUploadimg ) return this.$message.error('信息不完整,不可上传!'); 
				this.$refs.upload.submit();
			},
			isChangeUpload(file,fileList){
				return this.isUploadimg = true;
			},
			handleUploadForm(param){
				let fd = new FormData()// FormData 对象
				let allObj = {
					name:getUSERid().nickname,
					avatar:getUSERid().figureurl_1,
					time:current(),
					des:this.text
				};
				    fd.image = param;
					fd.append('image',param.file);
					fd.append('data',JSON.stringify(allObj));
				clientShareImg(fd).then(data=>{
					if(data.code===200){
						this.$message.success(data.msg);
						this.fileList = [{name:data.name,url:data.url}];
						this.text = '';//清空输入框
						this.init();
					}else{
						this.$message.warning(data.msg);
					}
				}).catch(err=>console.log(err));
			},
			handleRemove(file, fileList) {
				this.$myMessage({msg:'移除成功',icon:'el-icon-success',color:'color:green',duration:2000});
				return this.isUploadimg = false;
			},
			isToolong(files,fileList){
				return this.$myMessage({msg:'只能上传一张图片',icon:'el-icon-error',color:'color:red;',duration:2000});
			},
			isUploadSuccess(response, file, fileList){
				console.log('我是上传成功触发的钩子函数')
				console.log(response, file, fileList)
			},
			isUploadFail(err, file, fileList){
				console.log('我是上传失败的钩子函数')
				console.log(err, file, fileList)
			},
			isOnUpload(event, file, fileList){
				console.log('我是正在上传的钩子函数');
				console.log(event, file, fileList);
			},
			async init(){
				let ref = await clientShareInit();
				if(ref.code===200){
					this.shareData = ref.data;
					this.loading = false;
				}
			},
			async showMore() {
				this.loading = true;
				let ref = await clientShareShowMore();
				if(ref.code===200){
					let newData = ref.data; //Array [{}...]
					this.loading = false;
					this.shareData.push(...newData);
				}else{
					this.loading = false;
					this.$message(ref.msg);
				}
			},
			async thumbsUp($event,id){
			    if($event.target.getAttribute('isThum')==='false'){
					$event.currentTarget.style='color:red;transform: scale(1.1);transition:0.8s;display:inline-block;'
					let ref = await clientShareThumbs({type:'isThumb',remarkid:id});  //点赞
					if(ref.code===200){
						$event.target.setAttribute('isThum','true');
						this.$myMessage({msg:ref.msg,icon:'el-icon-success',color:'color:green;',duration:2000});
						this.init(); //评论区的刷新
					}
				}else{
					$event.currentTarget.style='color:;'
					let ref = await clientShareThumbs({type:'cancelThumb',remarkid:id});  //取消点赞
				    if(ref.code===100){
						$event.target.setAttribute('isThum','false');
				    	this.$myMessage({msg:ref.msg,icon:'el-icon-error',color:'color:red;',duration:2000});
						this.init();  //评论区的刷新
				    }
				}
			},
		},
		mounted() {
			this.init();//初始化
		}
	}
</script>

<style scoped="scoped">
	@font-face {
		font-family: 'iconfont';
		/* Project id 2406555 */
		src: url('//at.alicdn.com/t/font_2406555_l81ejnob52.woff2?t=1643183656908') format('woff2'),
			url('//at.alicdn.com/t/font_2406555_l81ejnob52.woff?t=1643183656908') format('woff'),
			url('//at.alicdn.com/t/font_2406555_l81ejnob52.ttf?t=1643183656908') format('truetype');
	}

	.des {
		font-size: 12px;
	}

	.shareContainer {
		margin-top: 20px;
	}

	.nickName {
		font-weight: bold;
		color: rgb(255, 87, 119);
	}

	.remarkDate {
		font-size: 14px;
		color: rgb(204, 204, 204);
	}

	.des {
		padding: 4px;
	}

	.showMore {
		color: rgb(24, 144, 255);
		text-align: center;
		cursor: pointer;
		padding: 30px 0px;
	}

	.shareList {
		cursor: pointer;
		transition: all 1s;
		border-radius: 5px;
	}

	.shareList:hover {
		transform: translateY(-3px);
		box-shadow: 1px 1px 20px #999;
	}
	.mainImg{
		transition: all 1s;
	}
	.mainImg:hover{
		transform: scale(1.1);
	}
	@media not screen and (min-width: 50em){
		/deep/.el-upload-dragger{
			width: 409px;
		}
	}
</style>

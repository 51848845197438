<template>
	<div class="sc-dlVyqM cvDNDF" v-loading="loading">
		<div class="music">
			<p>我喜欢听的歌不多</p>
			<p style="text-align: center;" class="to-top">歌曲库里只有40多首</p>
			<p style="text-align: right;">我想把我喜欢的分享出来</p>
		</div>
		<aplayer :audio="audio" :showlrc="true" :lrcType="1" v-if="flag"/>
		<aplayer :audio="audio" :showlrc="true" :lrcType="1" fixed v-if="flag" />
		<timeStamp></timeStamp>
	</div>
</template>

<script>
	import scrollReveal from "scrollreveal";
	import {
		clientGetMyMusic
	} from '../../server/api/index.js';
	import timeStamp from '../../components/timestamp/timestamp.vue';
	import Vue from 'vue';
	import aplayer from "@moefe/vue-aplayer";
	Vue.use(aplayer);
	export default {
		name: 'IndexMusic',
		data() {
			return {
				audio: [], // 音频列表
				flag: false, //可能会有用
				loading: true,
				scrollReveal: scrollReveal(),
			}
		},
		methods: {
			async initMusic() {
				let ref = await clientGetMyMusic();
				if (ref.code === 200) {
					ref.data.forEach((val) => {
						var obj = {
							name: val.title,
							artist: val.artist,
							url: val.music_url,
							cover: val.pic_cover,
							lrc: val.lrc,
							theme: this.randomColor() //随机主题样式(播放器)
						};
						this.audio.push(obj);
					})
					this.flag = true;
					this.loading = false;
				}
			},
			randomColor() {
				return `#${((Math.random() * 0xffffff) << 0).toString(16)}`; //随机色
			},
			animate(){
				this.scrollReveal.reveal('.to-top', {
					// 动画的时长
					duration: 1000,
					// 延迟时间
					delay:0,
					// 动画开始的位置，'bottom', 'left', 'top', 'right'
					origin: 'top',
					// 回滚的时候是否再次触发动画
					reset: true,
					// 在移动端是否使用动画
					mobile: true,
					// 滚动的距离，单位可以用%，rem等
					distance: '200px',
					// 其他可用的动画效果
					opacity: 0.001,
					easing: 'linear',
					scale: 0.5,
				})
			}
		},
		mounted() {
			this.initMusic();
			this.animate();
		},
		components:{
			timeStamp
		}
	}
</script>

<style scoped="scoped">
	.cvDNDF {
		background-color: white;
	}

	.cvDNDF .music {
		transition: all 0.4s ease 0s;
		background: url('https://blog-1303885568.cos.ap-chengdu.myqcloud.com/useImg/music_back2.jpg') 50% center / cover no-repeat;
		line-height: 40px;
	}

	.cvDNDF .music p {
		color: white;
		font-size: 17px;
		font-weight: bold;
		font-family: 楷体;
	}

	/deep/.aplayer .aplayer-lrc p {
		color: #93df5a;
	}

	/deep/.aplayer {
		margin-top: 10px;
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
	}
</style>

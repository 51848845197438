<template>
	<div style="margin-top: -454px;"  class="markdown-body"  v-html="html">
		
	</div>
</template>

<script>
	import { clientAboutMeMyinfo } from '../../server/api/index.js';
	export default{
		data(){
			return{
				html:'', //总数据
			}
		},
		methods:{
			async initMyInfo(){
			 	let ref = await clientAboutMeMyinfo();
				if(ref.code===200){
					this.html = ref.data[0].html;
				}
			}
		},
		mounted() {
			this.initMyInfo();
		}
	}
</script>

<style scoped="scoped">
	/deep/table th {
	  border: 1px solid #aca44b !important;
	  background-color: #e6e3bd!important;
	}
</style>
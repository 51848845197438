<template>
	<div>
		<div class="sc-kfPtPH dICMWV">
			<div class="banner">
				<div class="image"><img width="3000" height="250"
						src="https://dishiduo.coding.net/p/hcj/d/hcj/git/raw/master/2020-10/B%E7%AB%99/%E5%BD%95%E5%88%B6/images/bg.png">
				</div>
				<div class="image"><img width="1800" height="165"
						src="https://dishiduo.coding.net/p/hcj/d/hcj/git/raw/master/2020-10/B%E7%AB%99/%E5%BD%95%E5%88%B6/images/girl1.png">
				</div>
				<div class="image"><img width="3000" height="250"
						src="https://dishiduo.coding.net/p/hcj/d/hcj/git/raw/master/2020-10/B%E7%AB%99/%E5%BD%95%E5%88%B6/images/grassland.png">
				</div>
				<div class="image"><img width="1800" height="160"
						src="https://dishiduo.coding.net/p/hcj/d/hcj/git/raw/master/2020-10/B%E7%AB%99/%E5%BD%95%E5%88%B6/images/mushroom.png">
				</div>
				<div class="image"><img width="1800" height="165"
						src="https://dishiduo.coding.net/p/hcj/d/hcj/git/raw/master/2020-10/B%E7%AB%99/%E5%BD%95%E5%88%B6/images/spirit.png">
				</div>
				<div class="image"><img width="1950" height="178"
						src="https://dishiduo.coding.net/p/hcj/d/hcj/git/raw/master/2020-10/B%E7%AB%99/%E5%BD%95%E5%88%B6/images/leaf.png">
				</div>
			</div>

			<div class="Info">
				<div class="avat">
					<img src="https://blog-1303885568.cos.ap-chengdu.myqcloud.com/useImg/avat.jpg" alt="图片"
						style="width: 100px;">
				</div>
				<div class="info_right">
					<div class="name" style="color: rgb(24,144,255);font-weight: 600;">Passer</div>
					<div class="sex" style="font-size: 15px;">
						<span>
							<span class="el-icon-location" style="color: #13D2D2;"></span> 长沙|
						</span>
						<span>
							男 <span class="el-icon-male" style="color: #00BFFF;"></span>
						</span>
					</div>
					<div class="content">
						Hi,我叫李孟飞,为了练习学到的东西,做了一个小站,初来乍到,多有不足,请谅解！
					</div>
				</div>
			</div>
		</div>
		<h1 class="aboutMe">时间不等人</h1>
		<div class="container">
			<div class="clock">
				<div class="circle">
					<ul class="scale">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
					<ul class="number">
						<li>12</li>
						<li>15</li> 
						<li>18</li>
						<li>21</li>
					</ul>
				</div>

				<div class="hour"></div>
				<div class="min"></div>
				<div class="sec"></div>
			</div>
		</div>
		<myInfo></myInfo>
	</div>
</template>

<script>
	import myInfo from '../../components/myinfo/myinfo.vue';
	export default {
		data() {
			return {

			}
		},
		methods: {
			headAnimation() {
				let x = 0;
				let x_new = 0;
				let x_offset = 0;
				var blink_index;
				let banner = document.querySelector('.banner');
				let images = document.querySelectorAll('.image');
				let window_width = document.documentElement.clientWidth;
				let step = window_width / 2 / 5;
				let data_images = [{
						x: 0,
						b: 4
					},
					{
						x: 0,
						b: 0
					},
					{
						x: 0,
						b: 1
					},
					{
						x: 0,
						b: 4
					},
					{
						x: 0,
						b: 5
					},
					{
						x: 0,
						b: 6
					},
				];
				let init = () => {
					for (const key in images) {
						if (images.hasOwnProperty(key)) {
							const element = images[key];
							const element_data = data_images[key];
							element.children[0].style = 'transition: .2s linear; transform: translate(' + element_data
								.x +
								'px); filter: blur(' + element_data.b + 'px);';
						}
					}
				}
				banner.addEventListener('mouseover', (e) => {
					x = e.clientX;
					// console.log(x);
				});
				banner.addEventListener("mousemove", (e) => {
					x_new = e.clientX;
					// console.log(x_new);
					x_offset = x - x_new;
					// console.log(x_offset);
					for (const key in images) {
						if (images.hasOwnProperty(key)) {
							let level = (6 - parseInt(key)) * 10;
							const element = images[key];
							const element_data = data_images[key];
							let b_new = Math.abs(element_data.b + (x_offset / step));
							let l_new = 0 - (x_offset / level);
							element.children[0].style = 'transform: translate(' + l_new + 'px); filter: blur(' +
								b_new + 'px);';
						}
					}

				});
				banner.addEventListener("mouseout", (e) => {
					init();
				});
				blink_index = 0;
				let blink = () => {
					if (blink_index == 4) {
						blink_index = 1;

					} else {
						blink_index += 1;

					}
					images[1].children[0].src = 'images/girl' + blink_index + '.png';
				}
				window.onload = () => {
					init();
					blink();
				}
			},
			clockStart(){
				var hour = document.getElementsByClassName("hour")[0];
				var min = document.getElementsByClassName("min")[0];
				var sec = document.getElementsByClassName("sec")[0];
				this.clearTimeSet = setInterval(function () {
				       var time = new Date();
				       var h = time.getHours();
				       var m = time.getMinutes();
				       var s = time.getSeconds();
					   
				       hour.style.transform = `rotate(${h * 30 + (m / 60) * 30 + (s / 60 /60) * 30}deg)`;
				       min.style.transform = `rotate(${m * 6 + (s / 60) * 6}deg)`;
				       sec.style.transform = `rotate(${s * 6}deg)`;
				   }, 1000);
			},
		},
		mounted() {
			this.headAnimation();
			this.clockStart();
		},
		beforeDestroy() {
			clearInterval(this.clearTimeSet);  //离开页面就关闭定时器
		},
		components:{
			myInfo
		}
	}
</script>

<style scoped="scoped">
	ul,
	li {
		list-style: none;
	}
    @media not screen and (min-width: 35em) {
    	.banner{
			width: 100vw!important;
		}
    }
	.container {
		width: 100%;
		height: 100vh;
		/* background: red; */
		position: relative;
		top:-280px;
		left: -10px;
		transform: scale(0.8);
	}

	.clock {
		width: 180px;
		height: 180px;
		background-color: #f5f5f5;
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 90px);
		left: calc(50% - 90px);
		border: 16px solid #ffffff;
		box-shadow: inset 0 0 30px 0 rgba(52, 73, 94, 0.1),
			0 8px 15px 4px rgba(52, 73, 94, 0.3),
			0 0 2px 0px rgba(236, 240, 241, 1.0);
	}

	.scale li {
		width: 2px;
		height: 10px;
		background: #424242;
		position: absolute;
		top: 0;
		left: calc(50% - 1px);
		transform-origin: center 79px;
	}

	.scale li:nth-child(1) {
		transform: rotate(30deg);
	}

	.scale li:nth-child(2) {
		transform: rotate(60deg);
	}

	.scale li:nth-child(3) {
		transform: rotate(120deg);
	}

	.scale li:nth-child(4) {
		transform: rotate(150deg);
	}

	.scale li:nth-child(5) {
		transform: rotate(210deg);
	}

	.scale li:nth-child(6) {
		transform: rotate(240deg);
	}

	.scale li:nth-child(7) {
		transform: rotate(300deg);
	}

	.scale li:nth-child(8) {
		transform: rotate(330deg);
	}

	.number li {
		width: 20px;
		height: 20px;
		/* background: #424242; */
		color: #424242;
		font-size: 16px;
		font-weight: bold;
		position: absolute;
		/* top: 0;
    left: calc(50% - 10px); */
	}

	.number li:nth-child(1) {
		top: 0;
		left: calc(50% - 10px);
	}

	.number li:nth-child(2) {
		top: calc(50% - 10px);
		right: 0;
	}

	.number li:nth-child(3) {
		bottom: 0;
		left: calc(50% - 10px);
	}

	.number li:nth-child(4) {
		top: calc(50% - 10px);
		left: 0;
	}

	.circle {
		width: 158px;
		height: 158px;
		/* background: red; */
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 79px);
		left: calc(50% - 79px);
	}

	.clock::before {
		content: "";
		position: absolute;
		width: 14px;
		height: 14px;
		background-color: #424242;
		top: calc(50% - 7px);
		left: calc(50% - 7px);
		border-radius: 50%;
	}

	.hour,
	.min,
	.sec {
		width: 80px;
		height: 80px;
		/* background-color: red; */
		position: absolute;
		top: calc(50% - 40px);
		left: calc(50% - 40px);
		/* transform: rotate(45deg); */
	}

	.hour::before {
		content: "";
		position: absolute;
		width: 6px;
		height: 38px;
		background-color: #9e9e9e;
		z-index: 10;
		border-radius: 14px 14px 0 0;
		top: -7px;
		left: calc(50% - 3px);
	}

	.min::before {
		content: "";
		position: absolute;
		width: 4px;
		height: 50px;
		background-color: #9e9e9e;
		z-index: 10;
		border-radius: 14px 14px 0 0;
		top: -19px;
		left: calc(50% - 2px);
	}

	.sec::before {
		content: "";
		position: absolute;
		width: 2px;
		height: 80px;
		background-color: #c62828;
		z-index: 10;
		border-radius: 14px 14px 0 0;
		top: -27px;
		left: calc(50% - 1px);
	}


	/**---------------以上为时钟样式--------------------------*/
	.channel {
		position: absolute;
		width: 80%;
		text-align: center;
		bottom: 0%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 20px;
		font-weight: bold;
		color: #fff;
	}

	.aboutMe {
		color: rgb(122, 122, 122);
		text-align: center;
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: 500;
	}

	.banner {
		position: relative;
		width: 70vw;
		height: 165px;
		overflow: hidden;
		background-color: #00b894;
		cursor: pointer;
	}

	.image {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/*****************************************************/
	.dICMWV {
		transform: translateY(0%);
		animation: 0.6s ease 0s 1 normal forwards running aboutcss;
		border-radius: 5px;
		border-bottom: 1px solid rgb(24, 144, 255);
	}

	.dICMWV .Info .content {
		margin-top: 10px;
	}

	.dICMWV .Info {
		background-color: white;
		display: flex;
	}

	.dICMWV .Info .avat {
		margin-left: 40px;
		transform: translateY(-40px);
	}

	.dICMWV .Info .info_right {
		padding: 0px 5px 0px 10px;
		display: flex;
		flex-direction: column;
		font-size: 17px;
		font-family: 楷体;
	}

	.dICMWV .Info .avat img {
		border-radius: 10px;
	}
</style>

<template>
	<div class="center_wrap">
		<h1 class="link-title">友情链接</h1>
		<p class="link-title" style="font-size: 14px;">(顺序随机,不分先后)</p>
		<hr class="parting-line">
		<div class="eREiWo">
			<div class="sc-bBHwJV gRnrJX friend-item" v-loading="loading" v-for="(item,index) in friendLinkData"
				:key='index' @click="goOtherWebsite(item.websiteUrl)">
				<div class="left_wrap">
					<div class="title">
						<div style="color: deeppink;">{{item.website}}</div>
					</div>
					<div class="des">
						<div>{{item.webDescription}}</div>
					</div>
				</div>
				<img v-lazy="item.websiteImage" alt="图片" class="avat">
			</div>
		</div>
		<h2 class="link-title">欢迎各位大佬交换友链~~~</h2>
		<div class="apply_link">         
			<el-input placeholder="大佬网站名字" v-model="webName" clearable style='width: 70%;margin-top: 10px;'>
			</el-input>
			<el-input placeholder="大佬网站地址(请以HTTP或者HTTPS开头)" v-model="webUrl" clearable
				style='width: 70%;margin-top: 10px;'>
			</el-input>
			<el-input placeholder="对于网站的描述(或者网站的主要内容)" v-model="webDes"  clearable style='width: 70%;margin-top: 10px;'>
			</el-input>
			<el-input placeholder="网站的logo(以HTTPS或者HTTP开头)" v-model="webLogo" clearable 
				style='width: 70%;margin-top: 10px;'>
			</el-input>
			<el-input placeholder="QQ邮箱,用于友链审核结果的通知" v-model="email" clearable style='width: 70%;margin-top: 10px;'>
			</el-input>
		</div>
		<el-button type="primary" style='margin-top: 10px;' @click='subMitLinkApply'>申请友链</el-button>
		<div class="tip">
			<div style="color: deeppink;font-size: 20px;">本站格式</div>
			<div>标题:web前端赶路人</div>
			<div>地址:https://www.limengfei.top</div>
			<div>描述:我们不生产代码,我们只是代码的搬运工</div>
			<div>Logo:https://lmf401647220.oss-cn-beijing.aliyuncs.com/image/hostBlog2.jpg</div>
			<div>提示:申请提交后若无其它原因将在24小时内审核，如超过时间还未通过，请私信我。</div>
		</div>
		<div class="input_and_submit">
			<hr class="parting-line">
			<div class="dsy_tip to-friendLink">
				赶快来留言吖,博主会经常光顾本站
			</div>
			<div class="mysaid">
				<span>随便对站长说点什么吧❤</span>
				<span class="flex-wrap">
					<span>QQ邮箱提醒</span>
					<el-switch v-model="value" active-color="#13ce66" inactive-color="#ff4949">
					</el-switch>
				</span>
			</div>
		</div>
		<el-input
		  type="textarea"
		  :rows="6"
		  placeholder="请输入内容"
		  v-model="textarea"
		  >
		</el-input>
		<div class="operation">
			<el-button type="primary" style='margin-top:0px;' size='mini' @click='submitRemarkConment'>提交留言</el-button>
		</div>
		<div class="sc-dJjZJu kszVAv">
			<div>
				<div class="sc-hGPAah fidTJp commentItem0" v-for="(item,index) in remarkContent" :key="index">
					<div class="ant-comment">
						<div class="ant-comment-inner">
							<div class="ant-comment-avatar">
								<span class="ant-avatar ant-avatar-circle ant-avatar-image avatar">
									<img :src="item.avatar" alt="这是头像" style="width: 100%;height: 100%;">
								</span>
							</div>
							<div class="ant-comment-content">
								<div class="ant-comment-content-author">
									<span class="ant-comment-content-author-name">
										<a href="#" style="color: rgb(255,87,119);font-size: 12px;text-decoration: none;">{{item.author_name}}</a>
									</span>
									<span class="ant-comment-content-author-time">
										<span>
											<span>{{item.remark_date}}</span>
											<span style="margin-left: 5px;">{{item.province}}-{{item.city}}</span>
										</span>
									</span>
								</div>
								<div class="ant-comment-content-detail">
									<div class="markdown-body">{{item.remark}}</div>
								</div>
								<ul class="ant-comment-actions">
									<li>
										<span>
											<span class="iconfont" isThum='false' @click="friendThumb($event,item.id)">&#xec7f;</span>
											<span class="comment-action">{{item.star}}</span>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import scrollReveal from "scrollreveal";
	import {
		clientGetFriendLink,
		clientSubmitFriendLink,
		clientSubmitFriendLinkRemark,
		clientFriendLinkRemarkInit,
		clientFriendLinkThumb
	} from '../../server/api/index.js';
	import { getUSERid } from '../../server/api/store.js';
	import { current } from '../../server/api/tool.js';
	export default {
		data() {
			return {
				friendLinkData: [],
				loading: true,
				value: false, //switch
				textarea:'',
				webName:'',
				webUrl:'',
				webDes:'',
				webLogo:'',
				email:'',
				scrollReveal: scrollReveal(),
				remarkContent:[],
			}
		},
		methods: {
			async initFriendLink() {
				let ref = await clientGetFriendLink();
				if (ref.code === 200) {
					this.friendLinkData = ref.data;
					this.loading = false;
				}
			},
			goOtherWebsite(path) {
				return window.open(path);
			},
			async subMitLinkApply(){
				if(QC.Login.check()){
					var aqq=/^[1-9][0-9]{4,10}@qq.com$/;
					var http = /^(http|https):\/\/[\S]+$/;
					if(this.webName.length===0) return this.$myMessage({msg:'网站名称不能为空',icon:'el-icon-error',color:'color:red;',duration:2000});
					if(this.webUrl.length===0||(!http.test(this.webUrl))) return this.$myMessage({msg:'网址格式不正确',icon:'el-icon-error',color:'color:red;',duration:2000});
					if(this.webDes.length===0) return this.$myMessage({msg:'网站描述不能为空',icon:'el-icon-error',color:'color:red;',duration:2000})
					if(this.webLogo.length===0||(!http.test(this.webLogo))) return this.$myMessage({msg:'网站logo格式不正确',icon:'el-icon-error',color:'color:red;',duration:2000});
					if(this.email.length===0||(!aqq.test(this.email))) return this.$myMessage({msg:'QQ邮箱格式不正确',icon:'el-icon-error',color:'color:red;',duration:2000});
					let data = {
						webName:this.webName,
						webUrl:this.webUrl,
						webDes:this.webDes,
						webLogo:this.webLogo,
						email:this.email,
						user:getUSERid().nickname
					};
					let ref = await clientSubmitFriendLink(data);
					if(ref.code===200){
						this.$message.success(ref.msg);
						this.webName = '';
						this.webUrl = '';
						this.webDes = '';
						this.webLogo = '';
						this.email = '';
					}
				}else{
					this.$myMessage({msg:'请登录!',icon:'el-icon-error',color:'color:red;',duration:2000});
				}
			},
			async submitRemarkConment(){
				if(QC.Login.check()){
					if(this.value){   //通过qq邮箱提醒我  
					    if(this.textarea.length===0) return this.$myMessage({msg:'留言不能为空',icon:'el-icon-error',color:'color:red;',duration:2000});
						let ref = await clientSubmitFriendLinkRemark({comment:this.textarea,user:getUSERid().nickname,isEmail:this.value});
						if(ref.code===200){
							this.$message.success(ref.msg);
							this.textarea = '';
						}
					}else{
						if(this.textarea.length===0) return this.$myMessage({msg:'留言不能为空',icon:'el-icon-error',color:'color:red;',duration:2000});
						let contentInfo = new Object();
						contentInfo.texteara = this.textarea; //评论内容
						contentInfo.avatar = getUSERid().figureurl_1; //头像
						contentInfo.name = getUSERid().nickname;//昵称
						contentInfo.province = this.$store.state.positon.pro; //省份
						contentInfo.city = this.$store.state.positon.city;  //城市
						contentInfo.time = current();  //当前时间
					    let ref = await clientSubmitFriendLinkRemark(contentInfo);
					    if(ref.code===200){
					    	this.$message.success(ref.msg);
							this.textarea = '';
							this.initFriendLinkRemark();//刷新评论区
					    }else{
							this.textarea = '';
							this.$message.error(ref.msg);
						}	
					}
				}else{
					this.$myMessage({msg:'请登录!',icon:'el-icon-error',color:'color:red;',duration:2000});
				}
			},
			async initFriendLinkRemark(){
				let ref = await clientFriendLinkRemarkInit();
				if(ref.code===200){
					this.remarkContent = ref.data;
				}
			},
			async friendThumb($event,id){
				if($event.target.getAttribute('isThum')==='false'){
					$event.currentTarget.style='color:red;transform: scale(1.1);transition:0.8s;display:inline-block;'
					let ref = await clientFriendLinkThumb({type:'isThumb',remarkid:id});  //点赞
					if(ref.code===200){
						$event.target.setAttribute('isThum','true');
						this.$myMessage({msg:ref.msg,icon:'el-icon-success',color:'color:green;',duration:2000});
						this.initFriendLinkRemark();//刷新评论区
					}
				}else{
					$event.currentTarget.style='color:;'
					let ref = await clientFriendLinkThumb({type:'cancelThumb',remarkid:id});  //取消点赞
				    if(ref.code===100){
						$event.target.setAttribute('isThum','false');
				    	this.$myMessage({msg:ref.msg,icon:'el-icon-error',color:'color:red;',duration:2000});
						this.initFriendLinkRemark();//刷新评论区
				    }
				}
			},
			animate(){
				this.scrollReveal.reveal('.to-friendLink', {
					// 动画的时长
					duration: 1000,
					// 延迟时间
					delay:0,
					// 动画开始的位置，'bottom', 'left', 'top', 'right'
					origin: 'bottom',
					// 回滚的时候是否再次触发动画
					reset: true,
					// 在移动端是否使用动画
					mobile: true,
					// 滚动的距离，单位可以用%，rem等
					distance:'200px',
					// 其他可用的动画效果
					opacity:0.001,
					easing: 'linear',
					scale: 0.85,
				})
			}
		},
		mounted() {
			this.initFriendLink();
			this.animate();
			this.initFriendLinkRemark();
		},
		watch:{
			value(val,oldVal){
				if(val){
					this.$myMessage({msg:'此留言会通过邮箱提醒博主,且留言不会出现在评论区~~',icon:'el-icon-success',color:'color:green;',duration:2000});
				}else{
					this.$myMessage({msg:'已关闭此功能',icon:'el-icon-error',color:'color:red;',duration:2000});
				}
			}
		}
	}
</script>

<style scoped="scoped">
	@font-face {
	  font-family: 'iconfont';  /* Project id 2406555 */
	  src: url('//at.alicdn.com/t/font_2406555_orv7zhdzuyr.woff2?t=1633511915787') format('woff2'),
	       url('//at.alicdn.com/t/font_2406555_orv7zhdzuyr.woff?t=1633511915787') format('woff'),
	       url('//at.alicdn.com/t/font_2406555_orv7zhdzuyr.ttf?t=1633511915787') format('truetype');
	}
	.flex-column-wrap,
	.flex-wrap {
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
	}
    /deep/.el-textarea__inner{
		 background: url('https://blog-1303885568.cos.ap-chengdu.myqcloud.com/useImg/comment.png') right bottom no-repeat !important;
	}
	.ant-comment-actions>li {
	    display: inline-block;
	    color: rgba(0,0,0,.45);
	}
	.kszVAv {
	    overflow-x: hidden;
	    padding: 0px 10px;
	}
	.fidTJp .markdown-body {
	    margin-top: 0px;
		text-align: left;
	}
	.kszVAv .children {
	    margin-left: 40px;
	}
	@keyframes moveRight{
		0% {
		    transform: translateX(110%);
		},
		100% {
		    transform: translateX(0px);
		}
	}
	.fidTJp:hover .avatar {
	    transition: all 0.5s ease 0s;
	    transform: rotate(360deg);
	}
	.ant-avatar-image {
	    background: transparent;
	}
	.ant-comment-content-author>a, .ant-comment-content-author>span {
	    padding-right: 8px;
	    font-size: 12px;
	    line-height: 18px;
	}
	.ant-comment-content-author-name {
	    color: rgba(0,0,0,.45);
	    font-size: 14px;
	    transition: color .3s;
	}
	.ant-avatar {
	    box-sizing: border-box;
	    margin: 0;
	    padding: 0;
	    color: rgba(0,0,0,.85);
	    font-size: 14px;
	    font-variant: tabular-nums;
	    line-height: 1.5715;
	    list-style: none;
	    font-feature-settings: "tnum","tnum";
	    position: relative;
	    display: inline-block;
	    overflow: hidden;
	    color: #fff;
	    white-space: nowrap;
	    text-align: center;
	    vertical-align: middle;
	    background: #ccc;
	    width: 32px;
	    height: 32px;
	    line-height: 32px;
	    border-radius: 50%;
	}
	.ant-comment-content {
	    position: relative;
	    flex: 1 1 auto;
	    min-width: 1px;
	    font-size: 14px;
	    word-wrap: break-word;
	}
	.fidTJp {
	    display: block;
	    transition: all 0.5s ease 0s;
	    animation: 0.5s ease 0s 1 normal forwards running moveRight;
	}
	.operation {
	    text-align: right;
	    margin-top: 15px;
	}
	.mysaid {
		display: flex;
		justify-content: space-between;
		color: deeppink;
		align-items: center;
		font-size: 14px;
		margin: 4px 0px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	}

	.tip {
		color: rgb(161, 161, 161);
	}
    .ant-comment {
        position: relative;
        background-color: inherit;
    }
	.ant-comment-inner {
	    display: flex;
	    padding: 16px 0;
	}
	.tip div {
		font-size: 12px;
		margin-top: 5px;
		margin-bottom: 5px;
	}
.ant-comment-actions>li>span {
    margin-right: 10px;
    color: rgba(0,0,0,.45);
    font-size: 12px;
    cursor: pointer;
    transition: color .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
	.dsy_tip {
		padding: 5px 0px;
		text-align: center;
		font-size: 19px;
		font-weight: 600;
		color: rgb(153, 153, 153);
	}

	.eREiWo .friend-item .title {
		padding-bottom: 10px;
		color: rgba(218, 138, 118, 0.8);
		font-size: 16px;
		font-weight: bold;
	}
    
	.ant-comment-content-author-time {
	    color: #ccc;
	    white-space: nowrap;
	    cursor: auto;
	}
	
	.eREiWo .friend-item:hover::before {
		height: 700%;
	}
    .ant-comment-actions {
        margin-top: 12px;
        margin-bottom: inherit;
        padding-left: 0;
		text-align: left;
    }
	
	.eREiWo .friend-item::before {
		background-image: linear-gradient(-225deg, rgb(44, 216, 213) 0%, rgb(197, 193, 255) 56%, rgb(255, 186, 195) 100%);
		content: "";
		width: 100%;
		height: 0%;
		transform: translate(-50%, -50%) rotate(-45deg);
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		transition: all 0.3s linear 0s;
	}
    .ant-comment-avatar {
        position: relative;
        flex-shrink: 0;
        margin-right: 12px;
        cursor: pointer;
    }
	.gRnrJX .title {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
    .ant-comment-content-author {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 4px;
        font-size: 14px;
    }
	.eREiWo .friend-item .des {
		color: rgb(164, 164, 164);
		padding-top: 10px;
		border-top: 2px dashed rgb(238, 238, 238);
		font-size: 14px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}

	.center_wrap {
		text-align: center;
	}

	.eREiWo .friend-item:hover .avat {
		animation: 0.3s linear 0s 1 normal none running rotation;
		transform: rotate(360deg);
	}

	.eREiWo .friend-item .avat {
		transition: all 0.3s linear 0s;
		z-index: 2;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		border: 1px dashed rgba(204, 204, 204, 0.63);
	}

	.center_wrap .link-title {
		color: rgb(122, 122, 122);
		margin: 30px 0px;
		font-weight: bold;
		font-size: 24px;
	}

	.parting-line {
		height: 4px;
		border: 0px;
		background-color: rgb(221, 221, 221);
		margin: 20px 0px;
		background-image: repeating-linear-gradient(-45deg, rgb(255, 255, 255), rgb(255, 87, 119) 5px, transparent 0px, transparent 8px);
	}

	.eREiWo {
		font-family: 楷体;
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}

	.eREiWo .friend-item:hover {
		box-shadow: rgb(0 255 228) 0px 0px 8px;
		color: white;
	}

	.eREiWo .friend-item {
		margin-top: 10px;
		text-align: center;
		margin-left: 3%;
		background-color: white;
		overflow: hidden;
		position: relative;
		transition: all 0.3s linear 0s;
		cursor: pointer;
		height: 90px;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		width: 27%;
		padding: 10px;
		border: 1px solid rgba(225, 225, 225, 0.83);
		border-radius: 4px;
	}

	.gRnrJX {
		opacity: 1;
		animation: 0.8s ease 0s 1 normal forwards running dsyMove;
	}

	.eREiWo .friend-item .left_wrap {
		z-index: 2;
		flex: 1 1 0%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
	}

	@keyframes rotation {
		0% {
			transform: rotateY(360deg);
		}

		,
		100% {
			transform: rotateY(0deg);
		}
	}

	@keyframes dsyMove {
		0% {
			opacity: 0;
			transform: translateY(300px);
		},
		100% {
			opacity: 1;
			transform: translateY(0px);
		}
	}

	@media not screen and (min-width: 35em) {
		.eREiWo .friend-item {
			width: 40% !important;
		}
	}

	@media not screen and (min-width: 45em) {
		.eREiWo .friend-item {
			width: 40% !important;
		}
	}
</style>

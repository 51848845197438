import { render, staticRenderFns } from "./aboutMe.vue?vue&type=template&id=7121e1a5&scoped=true&"
import script from "./aboutMe.vue?vue&type=script&lang=js&"
export * from "./aboutMe.vue?vue&type=script&lang=js&"
import style0 from "./aboutMe.vue?vue&type=style&index=0&id=7121e1a5&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7121e1a5",
  null
  
)

export default component.exports
<template>
	<div class="myStamp" style="padding-top: 30px;">
		<el-timeline :reverse="reverse">
			<el-timeline-item 
			v-for="(activity, index) in activities" 
			:key="index" 
			:timestamp="activity.timestamp"
			:color='randomColor()'
			size='normal'
			class='animate__animated animate__bounceInRight'
			>
				<div :style="activity.current_color" class="des">{{activity.text_desciption}}</div>
			</el-timeline-item>
		</el-timeline>
	</div>
</template>

<script>
	import {clientTimestamp} from '../../server/api/index.js';
	export default {
		data() {
			return {
				reverse:false,
				activities: [],
				page:1,
				isOver:false,
			}
		},
		methods:{
			randomColor(){
				var color = "#";
				//for循环中，如果后面仅有一条语句，{}可省略不写
				//同上面方法
				for (var i = 0; i < 8; i++) color+=parseInt(Math.random() * 16).toString(16);
				return color;
			},
			async initData(page){
				let ref = await clientTimestamp({page:page});
				if(ref.code===100){
					this.isOver = true;//数据已经加载完毕
					this.$myMessage({msg:'数据加载完毕^_^',icon:'el-icon-success',color:'color:green;',duration:3000})
				}else{
					this.activities.push(...ref.data);
				}
			},
			getbottom() {                             //页面触底函数
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
				let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
				let numHeight = scrollTop + clientHeight;
				if (scrollHeight >= clientHeight && numHeight >=scrollHeight - 1) {
					if(!this.isOver){
						this.page++;
						this.initData(this.page);
					}
				}
			}
		},
		mounted() {
			this.initData(this.page);
			window.addEventListener('scroll', this.getbottom);
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.getbottom);//销毁页面滚动监听事件
		},
	}
</script>

<style scoped="scoped">
.el-timeline-item {
    text-align: center;
}
/deep/.el-timeline-item__wrapper {
        position: relative;
        left: 10px;
        padding-left: 28px;
        top: -2px;
        border-bottom: 1px dashed #e8dede;
        border: 1px dashed #e8dede;
        border-radius: 10px;
        height: 100px;
        line-height: 100px;
		width: 95%;
		background-color: #eaeaeac9;
}
.des{
	font-size: 12px;
	font-weight: 600;
}
</style>